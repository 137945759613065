import { Helmet } from "react-helmet"

import NoticeSide from "components/content/aside/NoticeSide"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import Section from "components/content/body/common/Section"
import UList from "components/content/body/notice/UList"
import List from "components/content/body/notice/List"
import Order from "components/content/body/notice/Order"
import Title from "components/content/body/notice/Title"
import Date from "components/content/body/notice/Date"
import { useLayoutEffect, useState } from "react"
import { getPhoto } from "controllers/gallery"


const Notice = () => {

  const [result, setResult] = useState<any[]>([]);

  useLayoutEffect(() => {
    getData();
  },[]);

  const getData = async() => {
    getPhoto(1)
    .then(res => {
      setResult(res);
      console.log(res);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  return (
    <>
      <Helmet>
        <title>성모사랑 데이케어 - 갤러리</title>
      </Helmet>
      <NoticeSide cur="갤러리" />
      <Container>
        <Header>
          갤러리
          <span>홈 &gt; 알림마당 &gt; 갤러리</span>
        </Header>
        <Section>
          <UList>
            {
              result && result.map((data:any) => (
                <List to={`/gallery/${data._id}`}>
                  <Order>{data.g_seq}</Order>
                  <Title>{data.title}</Title>
                  <Date>{data.date.slice(0,10)}</Date>
                </List>
              ))
            }
          </UList>
        </Section>
      </Container>
    </>
  )
}

export default Notice;