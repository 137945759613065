import styled from 'styled-components';

const Pay = () => {

  return (
    <>
      <Image1 />
      <Image2 />
      * 표기준 월 ~ 금요일 오전 08시 ~ 오후 06시 한달 22일 기준 / 식대 (중식 및 간식 2회 제공) 4,000원
    </>
  )
}

const Image1 = styled.div`
  background-image: url('images/entrance/table1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 180px;  
  margin-bottom: 20px;
`;

const Image2 = styled.div`
  background-image: url('images/entrance/table2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 240px;
  margin-bottom: 20px;
`;

export default Pay;

