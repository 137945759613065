import Header from 'containers/home/Header';
// component
import Container from 'components/home/common/Container';
import Gallery from 'components/home/gallery/Gallery';
import Post from 'components/home/post/Post';
import Center from 'components/home/center/Center';
import Map from 'components/home/map/Map';
import Footer from 'components/home/common/Footer';



const Index = () => {
  return (
    <>
      <Header />
      <Container>
        {/* 갤러리 */}
        <Gallery />
        {/* 공지사항 */}
        <Post />
        {/* 센터 입소 안내 */}
        <Center />
        {/* 지도 */}
        <Map/>
      </Container>
      <Footer />
    </>
  )
}

export default Index;