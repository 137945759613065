import styled from 'styled-components';

const Container = styled.div`
  max-width: 400px;
  width: 100%;
  position: absolute;
  height: fit-content;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

export default Container;