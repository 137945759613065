import Container from "components/home/header/Container";
import Dropdown from "components/common/header/Dropdown";
import List from "components/common/header/List";
import Nav from "components/common/header/Nav";
import UList from "components/common/header/UList";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Container>
        <Nav onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          <li>
            <div onClick={() => window.location.href="/"}/>
          </li>
          <li>
            <Link to="/centerintro">
            센터소개
          </Link>
          </li>
          <li>
            <Link to="/program">
              성모사랑 프로그램안내
            </Link>
          </li>
          <li>
            <Link to="/notice">
              알림마당
            </Link>
          </li>
          <li>
            <Link to="/about-insurance">
              노인 장기요양보험
            </Link>
          </li>
          <li>02)990-8875</li>
          <>
          {
          open && 
          <Dropdown onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)} onClick={() => setOpen(false)}>
            <UList>
              <List><Link to="/centerintro">인사말</Link></List>
              <List><Link to="/rule">운영규정</Link></List>
              <List><Link to="/facility">시설현황</Link></List>
              <List><Link to="/entrance">입소안내</Link></List>
              <List><Link to="/come">찾아오시는 길</Link></List>
            </UList>
            <UList>
              <List><Link to="/daycare">데이케어란?</Link></List>
              <List><Link to="/program">프로그램안내</Link></List>
              <List><Link to="/plan">운영계획표</Link></List>
            </UList>
            <UList>
              <List><Link to="/notice">공지사항</Link></List>
              <List><Link to="/gallery">갤러리</Link></List>
            </UList>
            <UList>
              <List><Link to="/about-insurance">노인장기요양보험이란?</Link></List>
              <List><a href="https://www.gov.kr/portal/service/serviceInfo/PTR000050683">노인장기요양보험신청</a></List>
            </UList>
          </Dropdown>
          }
          </>
        </Nav>
      </Container>
    </>
  )
}

export default Header;