import styled from 'styled-components';
import palette from 'lib/styles/palette';
import HomeLogo from 'components/admin/HomeLogo';
import List from 'components/admin/List';

const Header = (props:any) => {

  return (
  <Container>
    <Wrapper>
      <HomeLogo to={'/admin/notice'} />
      <List />
    </Wrapper>
  </Container>    
  )
}

const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 100px;
  background-color: ${palette.gray1};
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
`;

const Wrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export default Header;