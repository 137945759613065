import styled from 'styled-components';

const Pay = styled.div`
  width: 100%;
  height: 300px;
  background-image: url('images/come/Pay.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export default Pay;