import palette from 'lib/styles/palette';
import styled from 'styled-components';

const Box = styled.div`
  width: 400px;
  height: 300px;
  background-color: ${palette.gray1};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 50px;
`;

export default Box;