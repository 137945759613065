import styled from 'styled-components';
import palette from 'lib/styles/palette';

const Back = styled.button`
  width: 70px;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  background-color: ${palette.blue4};
`;

export default Back;