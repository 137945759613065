import Container from "components/content/body/common/Container"
import Wrapper from 'components/admin/notice/view/Wrapper';
import Top from 'components/admin/notice/view/Top';
import Date from 'components/admin/notice/view/Date';
import PostTitle from 'components/admin/notice/view/PostTitle';
import Content from 'components/admin/notice/view/Content';
import Back from 'components/admin/notice/view/Back';
import Imgs from 'components/content/body/notice/Imgs'
import { deleteNotice, getSelectedNotice } from 'controllers/notice';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet"
import NoticeSide from "components/content/aside/NoticeSide"

const NoticeView = () => {

  const [result, setResult] = useState<any>();
  const url = useLocation();

  const getData = async() => {
    const _url = url.pathname.slice(8);
    getSelectedNotice(_url)
    .then( res => {
      setResult(res);
      console.log(res);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  const onDelete = async() => {
    await deleteNotice(result.notice[0].seq);
    window.location.href="/admin/notice";
  }

  useEffect(() => {
    getData();
  },[]);

  return (
    <>
    <Helmet>
        <title>성모사랑 데이케어 - 공지사항</title>
      </Helmet>
      <NoticeSide cur="갤러리" />
     <Container>
      <Wrapper>
        <Top>
          <Date>
            {result && result.notice[0].date.slice(0,10)}
          </Date>
          <PostTitle>
            {result && result.notice[0].title}
          </PostTitle>
          <Back onClick={() => window.location.href="/notice"}>
            목록으로
          </Back>
        </Top>
        <Content>
          <Imgs>
          {result && result.notice[0].filename.reverse().map( (data:string, idx:number) => (
            <img src={`/api/v1/notice/load/${data}`} alt="첨부사진" key={idx} />
          ))}
          </Imgs>
          {result && result.notice[0].content.split('\n').map((data:any) => (
            <span>{data}<br/></span>
          ))}
        </Content>
      </Wrapper>
    </Container>
    </>
  )
}

export default NoticeView;