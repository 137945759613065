import CenterSide from "components/content/aside/CenterSide"
import H1 from "components/content/body/centerintro/H1";
import List from "components/content/body/centerintro/List";
import UList from "components/content/body/centerintro/UList";
import Container from "components/content/body/common/Container";
import Header from "components/content/body/common/Header";
import Section from "components/content/body/common/Section";
import SubHeader from "components/content/body/common/SubHeader";
import Sign from "components/content/image/Sign";
import { Helmet } from "react-helmet";

const Centerintro = () => {
  
  return (
    <>
    <Helmet>
      <title>성모사랑 데이케어 - 센터소개</title>
    </Helmet>
    <CenterSide cur="인사말"/>
    <Container>
      <Header>인사말<span>홈 &gt; 센터소개 &gt; 인사말</span></Header>
      <Section>
        <SubHeader>•인사말씀</SubHeader>
        <H1>성모사랑데이케어센터를 방문해 주셔서 감사합니다.</H1>
        우리나라도 이제 초고령화 시대를 맞이하면서 핵가족 또는 소가족화로 효의 가치관도 변하고 있습니다. 지병과 노화로 불편하신 부모님을 홀로 두거나 가정내에서 24시간 부양하는 것에 대한 가족간 갈등과 이에 따른 피로누적 등으로 장기요양시설에 대한 사회적 인식과 요구도 달라지고 있습니다.<br/>
        <br/>
이렇듯 사회적 실버화 시대에 뇌신경질환까지 동반되어 장기간의 입원생활로 가족의 삶은 정신적, 육체적, 경제적인 면에서 심각한 직면에 부딪히게 됩니다.<br/>
        <br/>
성모사랑데이케어센터는 이렇듯 부양에 부담되어버린 어르신을 낮 시간 주간보호서비스를 제공하며, 어르신의 건강 및 재활케어와 정서적교감을 형성하여 함께하는 동행의 메시지를 전달하여 고단했던 삶의 여정을 행복한 시간을 만들어 드리는 데 최선을 다하겠습니다.<br/>
        <br/>
늘 평화가 여러분 모두와 함께하길 기원합니다.<br/>
<Sign />
      </Section>
      <Section>
        <SubHeader>•센터소개</SubHeader>
        성모사랑데이케어센터의 운영진은 오랫동안 병원의 간호실무적, 행정실무 경험진과 물리치료사 등 전문인력을 바탕으로 
        뇌졸중이나 파킨슨 등으로 인한 상하지 또는 편마비 어르신과 정형외과적 만성 근골격계질환으로 일정부분 장애 및 노인성 치매와 고령의 만성 질환으로 단독생활의 어려움을 겪고 계신 
        어르신의 장기요양 서비스(1~5등급)를 받으실 분을 대상으로 낮 시간 중 어르신의 생활안정과 심신기능의 유지 및 향상에 도움을 드리고자 하고 있습니다.
        <UList>
          <List>○ 보호 어르신의 상태에 맞춘 서비스 플랜 수립
          </List>
          <List>○ 일일건강관리(혈압, 당뇨, 기본신장계측 및 투약)
            <span>- 의료기관연계서비스 : 건강상태의 변화에 따라 신속한 진료지원</span>
            </List>     
          <List>○ 식사 및 간식제공(개인별 영양식/당뇨식 제공)</List>  
          <List>○ 입체적 프로그램(정서/인지/취미/산책/시청각)</List>  
          <List>○ 특화된 뇌신경계 재활 / 근골격계 물리치료
            <span>- 뇌졸중, 파킨슨병 등 뇌병변으로 인한 상,하지 편마비 어르신에 대한 운동 및 작업치료, 인지치료 시행</span>
            </List>     
        </UList>
      </Section>
    </Container>
    </>
  )
}

export default Centerintro;