import palette from 'lib/styles/palette';
import styled from 'styled-components';

import Box from 'components/home/gallery/Box';
import Button from 'components/home/gallery/Button';
import ButtonBox from 'components/home/gallery/ButtonBox';
import More from 'components/home/gallery/More';
import SubTitle from 'components/home/gallery/SubTitle';
import Title from 'components/home/gallery/Title';
import { useEffect, useState } from 'react';
import { getPhoto } from 'controllers/gallery';
import { Link } from 'react-router-dom';

const Gallery = () => {

  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getPhoto(1)
    .then(res => {
      setResult(res);
      setIsLoading(false);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  },[])


  if(isLoading) return <></>
  return (
    <Container>
      <Box>
            <Title>갤러리</Title>
            <SubTitle>성모사랑 데이케어 센터에서의 추억입니다.</SubTitle>
            <More>+</More>
            <List>
              {
              result.map((data:any, idx:number) => (
                <>
                  <Content key={idx} to={`/notice/${data._id}`}>{data.title}</Content>
                </>
              ))
            }
            </List>
            <ButtonBox>
              <Button>
                <span>프로그램일정표</span>
                <span> &gt; </span>
              </Button>
              <Button>
                <span>주간식단표</span>
                <span>&gt;</span>
                </Button>
            </ButtonBox>
          </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  height: 500px;
  background-color: ${palette.blue1};
  display: flex;
  justify-content: center;
  position: relative;
`;

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  height: 300px;
`;

const Content = styled(Link)`
  width: 100%;
  padding: 10px 3px;
  position: relative;
  background-color: ${palette.white};
  cursor: pointer;
  color: ${palette.black};
  display: block;
  margin-bottom: 30px;
`;



export default Gallery;