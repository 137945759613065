import Container from "components/content/body/common/Container"
import Wrapper from 'components/admin/notice/view/Wrapper';
import Top from 'components/admin/notice/view/Top';
import Date from 'components/admin/notice/view/Date';
import PostTitle from 'components/admin/notice/view/PostTitle';
import Content from 'components/admin/notice/view/Content';
import Navigate from 'components/content/body/notice/Navigate';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet"
import NoticeSide from "components/content/aside/NoticeSide"
import { getSelectedGallery } from "controllers/gallery";
import Back from 'components/admin/notice/view/Back';

const NoticeView = () => {

  const [result, setResult] = useState<any>();
  const url = useLocation();

  const getData = async() => {
    const _url = url.pathname.slice(8);
    getSelectedGallery(_url)
    .then( res => {
      setResult(res);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  useEffect(() => {
    getData();
  },[]);

  return (
    <>
    <Helmet>
        <title>성모사랑 데이케어 - 공지사항</title>
      </Helmet>
      <NoticeSide cur="공지사항" />
     <Container>
      <Wrapper>
        <Top>
          <Date>
            {result && result.gallery[0].date.slice(0,10)}
          </Date>
          <PostTitle>
            {result && result.gallery[0].title}
          </PostTitle>
          <Back onClick={() => window.location.href="/gallery"}>
            목록으로
          </Back>
        </Top>
        <Content>
          {result && result.gallery[0].content}
          <div>
          {result && result.gallery[0].filename.map( (data:string, idx:number) => (
            <img src={`/api/v1/gallery/load/${data}`} alt="첨부사진" key={idx} />
          ))}
          </div>
        </Content>
      </Wrapper>
    </Container>
    </>
  )
}

export default NoticeView;