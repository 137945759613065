import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  padding: 20px 0;
  line-height: 24px;
  position: relative;

`;

export default Section;