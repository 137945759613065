import styled from 'styled-components';

const Sign = styled.div`
  width: 300px;
  height: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("images/sign.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export default Sign;