import styled from 'styled-components';

const Address = () => {

  return (
    <Container>
      <Title>주소</Title>
      <Text>서울특별시 도봉구 창동 181-3, 세영빌딩(마들로13길 165)<br/> 세영빌딩 5층</Text>
      <UnderTitle>버스를 이용해서 오시는 경우</UnderTitle>
      <Text>도봉 08번, 09번 버스, 노원 15번, 1119번 버스 <br/>
      창동역 동측 정류장 하차 후 자운초등학교 방향 도보 8분
      </Text>
      <UnderTitle>지하철을 이용해서 오시는 경우</UnderTitle>
      <Text>창동역 1번 출구 → 동아청솔아파트 방향 도보 8분</Text>
    </Container>
  )
}

const Container = styled.div`
  width: 35%;
  padding : 19px 0 0 30px;
  height: 400px;
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`;

const UnderTitle = styled.div`
  text-decoration: underline;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Text = styled.div`
  margin-bottom: 40px;
`;

export default Address;