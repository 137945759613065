import Container from 'components/admin/login/Container';
import Box from 'components/admin/login/Box';
import Title from 'components/admin/login/Title';
import Part from 'components/admin/login/Part';
import Input from 'components/admin/login/Input';
import Button from 'components/admin/login/Button';
import { signInUser } from 'controllers/auth';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { authContext } from 'modules/contexts/auth';

const Login = () => {

  const [id, setId] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const context = useContext(authContext);

  const handleKeyPress = (k:any) => {
    if( k.key === "Enter" ) loginAdmin()
  }

  useLayoutEffect(() => {
    const token = window.localStorage.getItem('daycare');
    if(token==='true') window.location.href='/admin/notice';
  },[]);

  const loginAdmin = () => {
    signInUser(id, pwd)
    .then(() => {
        window.location.href = "/admin/notice";
        
      })
    .catch(() => {
        window.alert('아이디나 비밀번호를 확인해주세요.');
    })
  }

  return (
      <Container onKeyPress={(k:any) => handleKeyPress(k)}>
      <Title />
      <Box>
        <Part>아이디</Part>
        <Input placeholder="아이디를 입력해주세요." type="text" onChange={(e:any) => setId(e.target.value)} />
        <Part>비밀번호</Part>
        <Input placeholder="비밀번호를 입력해주세요." type="password" onChange={(e:any) => setPwd(e.target.value)} />
        <Button type="button" 
              onClick={() => loginAdmin()}
              >
                로그인
        </Button>
      </Box>
    </Container>
  )
}

export default Login;