import {BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom';
import Globalstyle from 'components/common/Globalstyle';
import HomePage from 'pages/Homepage';
import ContentPage from 'pages/ContentPage';
import AdminPage from 'pages/AdminPage';
import { useContext, useEffect, useState } from 'react';
import { authContext } from 'modules/contexts/auth';
import { getUser } from 'modules/actions/auth';
import Modal from 'containers/modal';



const App = () => {

  const {dispatch: authDispatch} = useContext(authContext);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const url = useLocation();

  useEffect(() => {
    if (window.localStorage.getItem('daycare') !== 'true') {
      setIsLogin(false);
    } else {
      if(url.pathname.slice(1,6)==='admin') {
        getUser(authDispatch)
        .then( res => {
          setIsLogin(false);
        })
      }
      setIsLogin(false);
    }
  },[authDispatch]);
  
  if(isLogin) return <></>
  return (
    <>
    <Router>
      <Switch>
        <Route path="/" component={HomePage} exact/>
        {/* 센터소개 */}
        <Route path="/centerintro" component={ContentPage} exact/>
        <Route path="/rule" component={ContentPage} exact/>
        <Route path="/facility" component={ContentPage} exact/>
        <Route path="/entrance" component={ContentPage} exact/>
        <Route path="/come" component={ContentPage} exact/>
        {/* 프로그램안내 */}
        <Route path="/daycare" component={ContentPage} exact/>
        <Route path="/program" component={ContentPage} exact/>
        <Route path="/plan" component={ContentPage} exact/>
        {/* 공지사항 */}
        <Route path="/notice" component={ContentPage} exact/>
        <Route path="/notice/:num" component={ContentPage} exact/>
        <Route path="/gallery" component={ContentPage} exact/>
        <Route path="/gallery/:num" component={ContentPage} exact/>
        {/* 노인장기요양보험이란 */}
        <Route path="/about-insurance" component={ContentPage} exact/>
        {/* 관리자 */}
        <Route path="/admin" component={AdminPage} exact />
        <Route path="/admin/notice" component={AdminPage} exact />
        <Route path="/admin/gallery" component={AdminPage} exact />
        <Route path="/admin/notice/:num" component={AdminPage} exact />
        <Route path="/admin/gallery/:num" component={AdminPage} exact />
        {/* 오류처리 */}
        <Route path="*" component={HomePage} />
      </Switch>
    </Router>
    <Modal />
    <Globalstyle/>
    </>
  )
}

export default App;
