import styled from 'styled-components';

const Img = styled.div`
  background-image: url('images/insurance/about-insurance.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 900px;
`;

export default Img;