import styled from 'styled-components';

const Nav = styled.ul`
  max-width: 1440px;
  width: 100%;
  height: 86px;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  &>li {
    max-width: 160px;
    width: 100%;
    height: 86px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &>li>a {
    display: block;
    width: 100%;
    color: black;
    text-align: center;
  }

  &>li:nth-child(6) {
    font-size: 24px;
    color: yellowgreen;
    cursor: default;
  }

  &>li>div {
    max-width: 160px;
    width: 100%;
    height: 76px;
    background-image: url('images/home/MiniLogo.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 8px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    &>li {
      max-width: 120px;
      width: 100%;
    }

    &>li:nth-child(7) {
      font-size: 16px;
      color: yellowgreen;
      cursor: default;
    }
  }

  @media (max-width: 768px) {
    &>li {
      max-width: 100px;
      width: 100%;
    }
  }
`;

export default Nav;