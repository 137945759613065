import palette from 'lib/styles/palette';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Container>
      <Logo />
      <Information>
        <h4>성모사랑</h4>
        <span>데이케어센터</span>
        <div></div>
        <h4>문의전화</h4>
        <h5>02) 990-8875</h5>
        <h5>이용약관 | 개인정보처리방침</h5>
      </Information>
      <Represent>
        <span>대표자 : 신우진</span>
        <span>사업자등록번호 : 272-80-01848</span>
        <span>장기요양기관기호 : 2-11320-00486</span>
        <span>전화: 02)990-8875 / Fax: 02)990-8879</span>
        <span>서비스 급여종류 : 노인 주•야간 보호서비스</span>
        <div>Copyright ⓒ 성모사랑 데이케어센터 All right reserved</div>
      </Represent>
      <Govern />
    </Container>
  )
}

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 250px;
  background-color: ${palette.blue1};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 60px;
`;

const Logo = styled.div`
  background-image: url('images/big_logo.png');
  max-width: 86px;
  width: 100%;
  height: 110px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const Information = styled.div`
  width: 220px;
  margin: 0px 140px 0px 80px;
  & > h4 {
    font-size: 20px;
    display: inline-block;
    margin-bottom: 8px;
  }

  & > h5 {
    letter-spacing: 2px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;

const Represent = styled.div`
  width: 230px;
  font-size: 12px;
  & > span {
    display: block;
    margin-bottom: 8px;
  }
`;

const Govern = styled.div`
  max-width: 216px;
  width: 100%;
  height: 120px;
  background-image: url('images/home/LongTermCare.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export default Footer;