import palette from 'lib/styles/palette';
import styled from 'styled-components';

const SubHeader = styled.div`
  width: 100%;
  color: ${palette.blue5};
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  font-size: 20px;
`;

export default SubHeader;