import styled from 'styled-components';

const Box = styled.div`
  margin: 0 auto;
  max-width: 432px;
  width: 100%;
  padding: 18px 0;
  position: relative;
`;

export default Box;