import { IModal } from 'types/modules/modal';

/*
	Initial State
*/
export interface IModalState {
  modalList: IModal[];
}

const initialState: IModalState = {
  modalList: [],
};

export default initialState;
