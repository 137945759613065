import styled from 'styled-components';

const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  box-sizing: border-box;
`;

export default Container;