import palette from 'lib/styles/palette';
import styled from 'styled-components';

import Box from 'components/home/gallery/Box';
import SubTitle from 'components/home/gallery/SubTitle';
import Title from 'components/home/gallery/Title';
import More from 'components/home/gallery/More';
import { useEffect, useState } from 'react';
import { getHomeNotice } from 'controllers/notice';
import { Link } from 'react-router-dom';

const Post = () => {

  const [result, setResult] = useState([]);

  useEffect(() => {
    getHomeNotice()
    .then( res => {
      setResult(res);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }, []);

  return (
    <Container>
      <Box>
        <Title>공지사항</Title>
        <SubTitle>성모사랑 데이케어 센터에서 알려드립니다.</SubTitle>
        <More>+</More>
        <List>
          {
            result.map((data:any) => (
              
              <Content key={data._id} to={`/notice/${data._id}`}>{data.title}</Content>
              
            ))
          }
        </List>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  height: 500px;
  background-color: ${palette.blue0};
  display: flex;
  justify-content: center;
  position: relative;
`;

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Content = styled(Link)`
  width: 100%;
  padding: 10px 10px;
  position: relative;
  background-color: ${palette.white};
  cursor: pointer;
  color: ${palette.black};
  display: block;
  margin-bottom: 30px;
`;

export default Post;