import styled from 'styled-components';

const List = styled.li`
  color: #ff69b4;
  font-size: 18px;
  margin-bottom: 20px;
  &>span {
    color: black;
    display: block;
    font-size: 14px;
  }
`;

export default List;