import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  
`;

export default Wrapper;