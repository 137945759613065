import styled from 'styled-components';

const Title = () => {

  return (
    <Wrapper>
      <Logo src="images/big_logo.png" alt="로고" />
      <Name>성모사랑 데이케어센터 관리자</Name>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 4px;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 24px;
`;

export default Title;