import styled from 'styled-components';

const UList = styled.ul`
  max-width: 160px;
  width: 100%;
  &> li {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: rgba(199, 219, 221, .3);
  }

  @media (max-width: 1024px) {
    max-width: 120px;
    &>li {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100px;
    &>li {
      font-size: 10px;
    }
  }
`;

export default UList;