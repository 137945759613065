import styled from 'styled-components';
import palette from 'lib/styles/palette';

const Top = styled.div`
  width: 100%;
  height: 30px;
  padding: 4px 0;
  border-bottom: 2px solid ${palette.blue5};
  display: flex;
  flex-wrap: wrap;
`;

export default Top;