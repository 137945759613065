import styled from 'styled-components';

const PostTitle = styled.div`
  width: 80%;
  box-sizing: border-box;
  padding: 8px;
  display: inline-block;
  text-align: center;
`;

export default PostTitle;