import palette from 'lib/styles/palette';
import styled from 'styled-components';

const List = styled.li`
  text-align: center;
  margin-bottom: 8px;
  cursor: pointer;

  &>a:hover {
    color : ${palette.skyblue};
  }

  &>a {
    width: 100%;
    display: block;
    color: ${palette.black};
  }
`;

export default List;