import { signOutUser } from 'controllers/auth';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const List = () => {

  const onClick = async() => {
    signOutUser();
    await window.localStorage.removeItem('daycare');
    window.location.href = "/admin";
  }
  
  return (
    <Container>
      <Item to={"/admin/notice"}>공지사항</Item>
      <Item to={"/admin/gallery"}>갤러리</Item>
      <Logout onClick={() => onClick()}>로그아웃</Logout>
    </Container>
  )
}

const Container = styled.div`
  width: 250px;
  height: 100%;
  display: flex;
  & > * {
    width: 200px;
    margin: 0 10px;
  }
`;

const Item = styled(Link)`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logout = styled.div`
  cursor: pointer;
`;

export default List;