import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  line-height: 1.4em;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 16px;
`;

export default Content;