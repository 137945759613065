import styled from 'styled-components';

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 800px;
  height: 60px;
  margin: 0 auto;

  &>li:not(:first-child){
    margin-left: -1px;
  }
`;

export default Wrapper;