import styled from 'styled-components';

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
`;

export default Container;