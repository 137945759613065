import palette from 'lib/styles/palette';
import styled from 'styled-components';

const Card = () => {
 
  return (
    <Container>
      <First>
        <Box>
          <Step>1단계</Step>
          <Summary>장기요양등급판정</Summary>
        </Box>
      </First>
      <Second>
      <Box>
          <Step>2단계</Step>
          <Summary>이용상담(전화/방문)</Summary>
        </Box>
      </Second>
      <Third>
      <Box>
          <Step>3단계</Step>
          <Summary>이용신청(서류제출)</Summary>
        </Box>
      </Third>
      <Fourth>
      <Box>
          <Step>4단계</Step>
          <Summary>사정회의</Summary>
        </Box>
      </Fourth>
      <Fifth>
      <Box>
          <Step>5단계</Step>
          <Summary>서비스 계획 및<br/> 계약체결</Summary>
        </Box>
      </Fifth>
      <Sixth>
      <Box>
          <Step>6단계</Step>
          <Summary>서비스 제공</Summary>
        </Box>
      </Sixth>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: ${palette.white};
  height: 180px;
`;

const First = styled.div`
  max-width: 150px;
  width: 100%;
  height: 180px;
  background-color: ${palette.blue1};
  margin-right: 20px;

  display: flex;
  align-items: center;
`;

const Second = styled.div`
  max-width: 150px;
  width: 100%;
  height: 180px;
  background-color: ${palette.blue2};
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const Third = styled.div`
  max-width: 150px;
  width: 100%;
  height: 180px;
  background-color: ${palette.blue3};
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const Fourth = styled.div`
  max-width: 150px;
  width: 100%;
  height: 180px;
  background-color: ${palette.blue4};
  margin-right: 20px;
  display: flex;
  align-items: center;
  `;

const Fifth = styled.div`
  max-width: 150px;
  width: 100%;
  height: 180px;
  background-color: ${palette.blue5};
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const Sixth = styled.div`
  max-width: 150px;
  width: 100%;
  height: 180px;
  background-color: ${palette.blue6};
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  width: 100%;
`;

const Step = styled.h1`
  font-size: 32px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  text-align: center;
`

const Summary = styled.span`
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
`

export default Card;