const palette = {
  white: '#fff',
  black: '#333',
  // gray
  gray0: '#efefef',
  gray1: '#d0d0d0',
  gray2: '#535353',
  yellowgreen: '#9acd32',
  // blue
  blue0: '#dbe7e5',
  blue1: '#c2dcdd',
  blue2: '#b3e9ee',
  blue3: '#91edf5',
  blue4: '#39c3cf',
  blue5: '#2d99a2',
  blue6: '#257e86',
  // skyblue
  skyblue: '#6496ec'
}

export default palette;