import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 8px 16px;
  margin-top: 40px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    border : 1px solid black;
  }
`;

export default Button;