import ProgramSide from "components/content/aside/ProgramSide"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import Section from "components/content/body/common/Section"
import List from "components/content/body/program/List"
import Wrapper from "components/content/body/program/Wrapper"
import { useState } from "react"
import { Helmet } from "react-helmet"

const Program = () => {

  const [active, setActive] = useState<number>(1);
  return (
    <>
      <Helmet>
        <title>성모사랑 데이케어 - 데이케어란?</title>
      </Helmet>
      <ProgramSide cur="프로그램안내" />
      <Container>
        <Header>
          프로그램안내
          <span>홈 &gt; 성모사랑 프로그램안내 &gt; 프로그램안내</span>
        </Header>
        <Section>
          <Wrapper>
            <List active={active===1} onClick={() => setActive(1)}>생활프로그램</List>
            <List active={active===2} onClick={() => setActive(2)}>전문재활운동서비스</List>
            <List active={active===3} onClick={() => setActive(3)}>인지재활프로그램</List>
            <List active={active===4} onClick={() => setActive(4)}>의료보건케어서비스</List>
            <List active={active===5} onClick={() => setActive(5)}>멘토링서비스</List>
          </Wrapper>
        </Section>
      </Container>
    </>
  )
}

export default Program;