import styled from "styled-components";

const Imgs = styled.div`
  width: 100%;
  & img {
    max-width: 100%;
    height: auto;
    margin: 10px 10px;
    display: inline-block;
  }
`;

export default Imgs;