import styled from 'styled-components';

const FileBtn = styled.input`
  width: 180px;
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 8px;
  float: right;
`;

export default FileBtn;