import Fetch from './fetch';

export const getPhoto = (page_num:number) => {
  
  return Fetch(`/api/v1/gallery/${page_num}`,'GET')
    .then( res => {
      return res.results.images;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}

export const getSelectedGallery = (index:string) => {

  return Fetch(`/api/v1/gallery/selected/${index}`, 'GET')
    .then( res => {
      return res.results;
    })
    .catch( err => {
      return Promise.reject(err);
    })

}

export const deletePhoto = (seq:number) => {
  const sendData = {
    seq
  }
  return Fetch(`/api/v1/gallery`,'DELETE', sendData)
    .then( res => {
      return res.success;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}

export const putPhoto = () => {

}

export const postPhoto = (sendData:FormData) => {

  return Fetch('/api/v1/gallery/upload','POST', sendData)
    .then( res => {
      return res.results;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}