// controllers
import * as authAPI from 'controllers/auth'

/**
 * 토큰 발행 후, 콜백함수 실행
 * - HTTP 상태코드 401, 422에 한하여 토큰 재발행
 * 
 * @param url 현재 API URL
 * @param statusCode 현재 API 반환된 HTTP 상태코드
 * @param callback 콜백함수
 */
const reAuthorization = async (
	url: string, 
	statusCode: number, 
	callback: Function
) => {
	if (url !== "/api/v1/auth/access-token") {
		const tokenResponse = await authAPI.renewalToken();
	
		if (tokenResponse.success) {
			return await callback();
		}
	}
	
	authAPI.signOutUser();
	return Promise.reject({
		statusCode,
		response: { msg: "Re Authorization failed." }
	});
}

export default reAuthorization