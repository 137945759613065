import { Helmet } from 'react-helmet';
import Container from 'components/admin/Container';
import { Route } from 'react-router-dom';
import Notice from 'containers/admin/Notice';
import NoticeView from 'containers/admin/NoticeView';
import GalleryView from 'containers/admin/GalleryView';
import Gallery from 'containers/admin/Gallery';
import Login from 'containers/admin/Login';

const AdminPage = () => {
  return (
    <>
      <Helmet>
        <title>성모사랑 데이케어센터 - 주야간보호시설</title>
      </Helmet>
      <Container>
        <Route path="/admin" component={Login} exact />
        <Route path="/admin/notice" component={Notice} exact />
        <Route path="/admin/notice/:id" component={NoticeView} exact />
        <Route path="/admin/notice/page/:num" component={Notice} exact />
        <Route path="/admin/gallery" component={Gallery} exact />
        <Route path="/admin/gallery/:id" component={GalleryView} exact />
        <Route path="/admin/gallery/page/:num" component={Gallery} exact />
      </Container>
    </>
  )
}

export default AdminPage;