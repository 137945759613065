import styled from 'styled-components';

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 378px;
  background-image: url('images/Banner.jpeg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
`;

export default Container;