import palette from 'lib/styles/palette';
import { useState } from 'react';
import styled from 'styled-components';

const Photozone = (props:any) => {
  
  const [order, setOrder] = useState<number>(1);
  const [url, setUrl] = useState<string>(`images/facility/facility${order}.png`);

  return (
    <>
      <Container url={`images/facility/facility${order}.png`}>

      </Container>
      <DotWrapper>
        <Dot onClick={() => setOrder(1)} order={order===1}/>
        <Dot onClick={() => setOrder(2)} order={order===2}/>
        <Dot onClick={() => setOrder(3)} order={order===3}/>
        <Dot onClick={() => setOrder(4)} order={order===4}/>
        <Dot onClick={() => setOrder(5)} order={order===5}/>
        <Dot onClick={() => setOrder(6)} order={order===6}/>
      </DotWrapper>
    </>
  )
}

const Container = styled.div<{url:string}>`
  margin : 50px auto 30px auto;
  width: 700px;
  height: 500px;
  box-sizing: border-box;
  position: relative;
  background-image: url("${props => props.url}");
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const DotWrapper = styled.div`
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Dot = styled.div<{order:boolean}>`
  margin : 0 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.order ? palette.gray2 : palette.gray1};
  cursor: pointer;
`;

export default Photozone;