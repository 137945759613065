import {produce} from 'immer';
import userState, { IUserState } from 'modules/states/auth';
import * as actions from 'modules/actions/auth';
import React ,{ createContext, Dispatch, useReducer } from 'react';

export const reducer = (
  state:IUserState = userState,
  action:actions.ActionType
):IUserState => {
  switch(action.type) {
    case actions.UPDATE_USER:
      return produce(state, draft => {
        if(!action.payload) return ;
        draft.user_id = action.payload.id;
      });

    case actions.DELETE_USER:
      return produce(state, draft => {
        draft.user_id = "";
      });

    default :
      return state; 
    } 
};

export const authContext = createContext<{
	state: IUserState;
	dispatch: Dispatch<actions.ActionType>;
}>({ state: userState, dispatch: () => null });

const Provider = ({ children }: { children: React.ReactChild }) => {
	const [state, dispatch] = useReducer(reducer, userState);
	const value = { state, dispatch };

	return (
		<authContext.Provider value={value}>
			{children}
		</authContext.Provider>
	);
}

export default Provider