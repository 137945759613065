import CenterSide from "components/content/aside/CenterSide"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import List from "components/content/body/rule/List"
import UList from "components/content/body/rule/UList"
import Section from "components/content/body/common/Section"
import SubHeader from "components/content/body/common/SubHeader"
import { Helmet } from "react-helmet"

const Rule = () => {

  return (
    <>
    <Helmet>
      <title>성모사랑 데이케어 - 운영규정</title>
    </Helmet>
    <CenterSide cur="운영규정" />
    <Container>
      <Header>
        운영규정
        <span>홈 &gt; 센터소개 &gt; 운영규정</span>
      </Header>
      <Section>
        <SubHeader>•운영규정</SubHeader>
        <UList>
          <List>
            <h1>1.종사자 윤리지침</h1>
            <span>수급자에 대한 윤리, 전문직으로서의 윤리</span>
          </List>
          <List>
            <h1>2.성폭력 예방 및 대응지침</h1>
            <span>성폭력 유형 예방, 대응 방법</span>
          </List>
          <List>
            <h1>3.응급상황 및 대응지침</h1>
            <span>응급상황 종류 : 응급상황 발생 시 대응방법 교육</span>
          </List>
          <List>
            <h1>4.감염예방 및 관리지침</h1>
            <span>감염병 예방 및 관리 지침</span>
            <span>매일 오전 코로나 관련 소독 작업 실시 (등원 전)</span>
          </List>
          <List>
            <h1>5.치매예방 및 관리지침</h1>
            <span>치매 종류 증상, 예방 및 관리</span>
          </List>
          <List>
            <h1>6.욕창 예방 및 관리 지침</h1>
            <span>욕창발생요인 예방 및 관리</span>
          </List>
          <List>
            <h1>7.낙상 예방 및 관리지침</h1>
            <span>낙상 요인 예방 및 관리지침, 발생 시 응급처치</span>
          </List>
          <List>
            <h1>8.노인 인권보호지침</h1>
            <span>노인 권리 보호, 학대유형 및 예방</span>
          </List>
          <List>
            <h1>9.근골격 질환 예방 및 지침</h1>
            <span>근골격 질환(류마티스성 관절염, 노인성 관절염) 예방 및 지침사항 준수</span>
          </List>
          <List>
            <h1>10.개인정보보호 지침</h1>
            <span>수집 및 이용과 목적, 보호 및 이용기간</span>
          </List>
        </UList>
      </Section>
    </Container>
    </>
  )
}

export default Rule;