import palette from 'lib/styles/palette';
import styled from 'styled-components';

const InputTitle = styled.input`
  max-width: 100%;
  width: 100%;
  height: fit-content;
  padding: 4px;
  border: 1px solid ${palette.gray2};
  border-radius: 8px;
  font-size: 18px;
  margin: 10px 0 ;
`;

export default InputTitle;