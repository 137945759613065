import Fetch from 'controllers/fetch';
import reAuthorization from './reAuthorization';

/** 유저 정보 호출 
@param { none } 유저 가입에 필요한 정보
@version 1
*/
export const userInfo = () => {
  return Fetch('/api/v1/user','GET')
      .then(async(res) => {
        if(res.success) {
          return res.results;
        } else if (res.statusCode === 401) {
          console.log('401 userInfo');
          return await reAuthorization('/api/v1/user', res.statusCode, userInfo);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      })
}

/** 로그인
@param {id, pwd} 로그인에 필요한 정보
@version 1
*/
export const signInUser = (id:string, pwd:string) => {
  const sendData = {
    id,
    pwd
  };

  return Fetch('/api/v1/auth/signin','POST', sendData)
      .then(res => {
        if(res.success) {
          window.localStorage.setItem('daycare', 'true');
          return res.results;
        } else {
          return Promise.reject(res);
        }
      });
}

/** 로그아웃
@param { none } 유저 가입에 필요한 정보
@version 1
*/
export const signOutUser = () => {
  return Fetch('/api/v1/auth/logout','GET')
      .then(res => {
        window.localStorage.removeItem('daycare');
        return res;
      })
      .catch(err => {
        return Promise.reject(err);
      })
}

/**
 * 토큰 갱신 API
 * @version 1
 */
export const renewalToken = () => {
	return Fetch("/api/v1/auth/access-token", "GET")
		.then(res => {
			if (res.success) {
				return Promise.resolve(res);
			} else {
				signOutUser();
				return Promise.reject(res);
			}
		});
}