import Fetch from './fetch';

export const getNotice = () => {

  return Fetch('/api/v1/notice/1', 'GET')
    .then( res => {
      return res.results;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}

export const getSelectedNotice = (index:string) => {

  return Fetch(`/api/v1/notice/selected/${index}`,'GET')
      .then( res => {
        return res.results;
      })
      .catch( err => {
        return Promise.reject(err);
      })
}

export const getPageNotice = (page_num:number) => {

  return Fetch(`/api/v1/notice/${page_num}`,'GET')
    .then( res => {
      return res.results.notices;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}

export const postNotice = (sendData:FormData) => {

  return Fetch('/api/v1/notice','POST', sendData)
    .then( res => {
      return res.results;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}

export const deleteNotice = (seq:number) => {

  const sendData = {
    seq
  }

  return Fetch('/api/v1/notice', 'DELETE', sendData)
    .then( res => {
      return res.results;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}

export const putNotice = () => {
  
}

export const getHomeNotice = () => {

  return Fetch('/api/v1/notice/9999' , 'GET')
    .then( res => {
      return res.results;
    })
    .catch( err => {
      return Promise.reject(err);
    })
}