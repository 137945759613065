import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomeLogo = styled(Link)`
  background-image: url('images/big_logo.png');
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
`;

export default HomeLogo;