import palette from 'lib/styles/palette';
import styled from 'styled-components';

const Header = styled.div`
  border-bottom: 1px solid ${palette.blue1};
  display: flex;
  padding-bottom: 4px;
  font-size: 18px;
  & > span {
    flex:1;
    font-size: 12px;
    color: ${palette.gray1}; 
    text-align: right;
  }
`;

export default Header;