import CenterSide from "components/content/aside/CenterSide"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import Section from "components/content/body/common/Section"
import SubHeader from "components/content/body/common/SubHeader"
import Pay from "components/content/body/entrance/Pay"
import Solution from "components/content/body/entrance/Solution"
import { Helmet } from "react-helmet"

const Entrance = () => {

  return (
    <>
    <Helmet>
      <title>성모사랑 데이케어 - 입소안내</title>
    </Helmet>
    <CenterSide cur="입소안내" />
    <Container>
      <Header>
        입소안내
        <span>홈 &gt; 센터소개 &gt; 입소안내</span>
      </Header>
      <Section>
          <SubHeader>•입소대상</SubHeader>
          만 65세 이상의 노인 또는 만 65세 미만의 자로서 노인성 질병을 가진자 중 장기요양등급판정위원회에서 1~5동급, 인지지원등급으로
          인정받은 거동가능 어르신 ( 등급신청 전 이라도 등급 외 입소가능 : 별도상담 )
      </Section>
      <Section>
          <SubHeader>입소정원</SubHeader>
          48명
      </Section>
      <Section>
        <Solution />
      </Section>
      <Section>
        <SubHeader>•이용시간</SubHeader>
        주간보호 (월 ~ 금) 08:00 ~ 18:00 / 토요일 08:00 ~ 17:00<br/>
        야간보호 (월 ~ 금) 18:00 ~ 20:00
      </Section>
      <Section>
        <SubHeader>•입소비용</SubHeader>
        <Pay />
      </Section>
    </Container>
    </>
  )
}

export default Entrance;