import Container from 'components/content/common/Container';
import AboutInsurance from 'containers/content/AboutInsurance';
import Centerintro from 'containers/content/Centerintro';
import Come from 'containers/content/Come';
import Daycare from 'containers/content/Daycare';
import Entrance from 'containers/content/Entrance';
import Facility from 'containers/content/Facility';
import Gallery from 'containers/content/Gallery';
import Header from 'containers/content/Header';
import Footer from 'components/home/common/Footer';
import Notice from 'containers/content/Notice';
import Plan from 'containers/content/Plan';
import Program from 'containers/content/Program';
import Rule from 'containers/content/Rule';
import NoticeView from 'containers/content/NoticeView';
import GalleryView from 'containers/content/GalleryView';
import {BrowserRouter as Router, Route} from 'react-router-dom';

const ContentPage = () => {

  return (
    <>
      <Header />
      <Container>
       <Route exact path="/centerintro" component={Centerintro}/>
       <Route exact path="/rule" component={Rule}/>
       <Route exact path="/facility" component={Facility}/>
       <Route exact path="/entrance" component={Entrance}/>
       <Route exact path="/come" component={Come}/>
       <Route exact path="/daycare" component={Daycare}/>
       <Route exact path="/program" component={Program}/>
       <Route exact path="/plan" component={Plan}/>
       <Route exact path="/notice" component={Notice}/>
       <Route exact path="/notice/:num" component={NoticeView}/>
       <Route exact path="/gallery" component={Gallery}/>
       <Route exact path="/gallery/:num" component={GalleryView}/>
       <Route exact path="/about-insurance" component={AboutInsurance}/>
      </Container>
      <Footer />
    </>
  )
}

export default ContentPage;