import styled from 'styled-components';

const List = styled.li`
  margin-bottom: 8px;
  & > h1 {
    font-size: 20px;
    font-weight: 500;
  }
  & > span {
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: 400;
  }
`;

export default List;