import palette from 'lib/styles/palette';
import styled from 'styled-components';

const List = styled.li<{active:boolean}>`
  color: ${props => props.active ? palette.white : palette.black};
  background-color: ${props => props.active ? palette.gray1 : palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${palette.gray1};
`;

export default List;