import Container from 'components/admin/notice/Container';
import ListBox from 'components/admin/notice/ListBox';
import Title from 'components/admin/Title';
import Header from 'components/admin/Header';
import Date from 'components/admin/Date';
import PostTitle from 'components/admin/PostTitle';
import Button from 'components/admin/Button';
import Post from 'components/admin/Post';
import Pagination from 'components/common/Pagination';
import { useContext, useEffect, useState} from 'react';
import { modalContext } from 'modules/contexts/modal';
import { pushModal } from 'modules/actions/modal';
import PostNotice from 'containers/admin/PostNotice';
import { getPageNotice } from 'controllers/notice';
import { useHistory } from 'react-router-dom';

const Notice = () => {
  
  const [result, setResult] = useState<any[]>([]);
  const [isFlag, setIsFlag] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const { dispatch: modalDispatch } = useContext(modalContext);
  const history = useHistory();

  const addPostNoticeModal = () => modalDispatch(pushModal('postNotice', PostNotice));

  useEffect(() => {
    const token = window.localStorage.getItem('daycare');
    if(token==='true') setIsFlag(false);
    getPageNotice(page)
    .then( res => {
      setResult(res);
    })

    history.push({
      search: `&page=${page}`
    })
  }, []);

  if(isFlag) return <></>
  return (
        <>
        <Header />
        <Container>
          <Title>공 지 사 항</Title>
          <Button type="button" onClick={() => addPostNoticeModal()}>글 쓰 기</Button>
          <ListBox>
            {
              result && result.map((data:any, idx:number) => (
                <Post to={`/admin/notice/${data._id}`} key={idx}>
                  <Date>
                    {
                      data.date.slice(0,10)
                    }
                  </Date>
                  <PostTitle>
                    {
                      data.title
                    }
                  </PostTitle>
                </Post>
              ))
            }
          </ListBox>
          <Pagination page={page} setPage={setPage}/>
        </Container>
      </>
      )
}

export default Notice;