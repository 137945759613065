import palette from 'lib/styles/palette';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NoticeSide = ({cur}:ISide) => {

  return (
    <Container>
      <List to="/notice" check={cur==="공지사항"}>공지사항</List>
      <List to="/gallery" check={cur==="갤러리"}>갤러리</List>
      <Tail>
        문의전화<br/>
        02-990-8875<br/>
        24시간 연중무휴
      </Tail>
    </Container>
  )
}

const Container = styled.div`
  max-width: 228px;
  width: 100%;
  &>a:not(:first-child) {
    margin-top: -1px;
  }
`;

const List = styled(Link)<{check:boolean}>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color:${props => props.check ? `${palette.gray1}` : `${palette.white}`};
  color: ${props => props.check ? `${palette.white}` : `${palette.black}`};
  border: ${props => props.check ? `none` : `1px solid ${palette.gray1}`};
`;

const Tail = styled.div`
  background-color: ${palette.blue1};
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
`;

interface ISide {
  cur: string;
}

export default NoticeSide;