import styled from 'styled-components';
import palette from 'lib/styles/palette';
import { useEffect } from 'react';

const MiniMap = () => {

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=f627998f1d3b3dcc3f5931594eaae8cc&autoload=false";
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load(() => {
        let container = document.getElementById("map");
        let options = {
          center: new window.kakao.maps.LatLng(37.657806364145245, 127.04588772464226),
          level: 3
        };

        const map = new window.kakao.maps.Map(container, options);

        let markerPosition = new window.kakao.maps.LatLng(
          37.657806364145245, 
          127.04588772464226
        );
    
        // 마커를 생성
        let marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });
    
        // 마커를 지도 위에 표시
        marker.setMap(map);
      });
    };
  }, []);
  return (

    <Map id="map">

    </Map>
  )
}

const Map = styled.div`
  width: 45%;
  height: 400px;
  background-color: white;

  @media (max-width: 1024px) {
    
  }
`;

export default MiniMap;