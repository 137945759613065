import styled from 'styled-components';

const Button = styled.button`
  width: 100px;
  padding: 8px;
  box-sizing: border-box;
  position: absolute;
  right: 30px;
  cursor: pointer;
`;

export default Button;