import palette from 'lib/styles/palette';
import styled from 'styled-components';

const More = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.gray2};
  font-weight: bold;
  color: ${palette.white};
  font-size: 24px;
  cursor: pointer;
`;

export default More;