import CenterSide from "components/content/aside/CenterSide"
import Map from "components/content/body/come/Map"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import Section from "components/content/body/common/Section"
import SubHeader from "components/content/body/common/SubHeader"
import { Helmet } from "react-helmet"

const Come = () => {

  return (
    <>
      <Helmet>
        <title>성모사랑 데이케어 - 찾아오시는 길</title>
      </Helmet>
      <CenterSide cur="찾아오시는 길" />
      <Container>
        <Header>찾아오시는 길<span>홈 &gt; 센터소개 &gt; 찾아오시는 길</span></Header>
        <Section>
          <Map />
          <Section>
            <SubHeader>•주소</SubHeader>
            서울특별시 도봉구 창동 181-3(마들로 13길 165) 세영빌딩 5층
          </Section>
          <Section>
            <SubHeader>•지하철을 타고 오시는 경우</SubHeader>
            창동역 1번출구 → 동아청솔아파트 방향 도보 8분
          </Section>
            <SubHeader>•버스를 타고 오시는 경우</SubHeader>
            도봉 08번/09번 버스, 노원 15번/1119번 버스 창동역 동측 정류장 하차 후 자운초등학교 방향 도보 8분
        </Section>
      </Container>
    </>
  )
}

export default Come;