import Title from 'components/admin/Title';
import Header from 'components/admin/Header';
import Container from 'components/admin/notice/Container';
import Wrapper from 'components/admin/notice/view/Wrapper';
import Top from 'components/admin/notice/view/Top';
import Date from 'components/admin/notice/view/Date';
import PostTitle from 'components/admin/notice/view/PostTitle';
import Content from 'components/admin/notice/view/Content';
import Delete from 'components/admin/notice/view/Delete';
import Back from 'components/admin/notice/view/Back';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { deleteNotice, getSelectedNotice } from 'controllers/notice';

const NoticeView = () => {
  
  const [result, setResult] = useState<any>();
  const url = useLocation();

  const getData = async() => {
    const _url = url.pathname.slice(14);
    getSelectedNotice(_url)
    .then( res => {
      setResult(res);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  const onDelete = async() => {
    await deleteNotice(result.notice[0].seq);
    window.location.href="/admin/notice";
  }

  useEffect(() => {
    getData();
  },[]);

  return (
    <>
    <Header />
    <Container>
      <Title>공지사항</Title>
      <Wrapper>
        <Top>
          <Date>
            {result && result.notice[0].date.slice(0,10)}
          </Date>
          <PostTitle>
            {result && result.notice[0].title}
          </PostTitle>
          <Delete onClick={() => onDelete()}>
            삭 제
          </Delete>
          <Back onClick={() => window.location.href="/admin/notice"}>
            목록으로
          </Back>
        </Top>
        <Content>
          {result && result.notice[0].content}
          <div>
          {result && result.notice[0].filename.map( (data:string, idx:number) => (
            <img src={`/api/v1/notice/load/${data}`} alt="첨부사진" key={idx} />
          ))}
          </div>
        </Content>
      </Wrapper>
    </Container>
    </>
  )
}

export default NoticeView;