import styled from 'styled-components';

const ListBox = styled.ul`
  max-width: 1024px;
  width: 100%;
  height: fit-content;
  height: fit-content;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5px;
  margin-bottom: 20px;
`;

export default ListBox;