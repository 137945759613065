import palette from 'lib/styles/palette';
import styled from 'styled-components';

const Dropdown = styled.div`
  max-width: 644px;
  width: 100%;
  position: absolute;
  top: 86px;
  
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: ${palette.white};
  border-left: 2px solid ${palette.yellowgreen};
  border-right: 2px solid ${palette.yellowgreen};
  border-bottom: 2px solid ${palette.yellowgreen};
`;

export default Dropdown;