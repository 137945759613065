import styled from 'styled-components'

const Solution = styled.div`
  background-image: url('images/entrance/entrance.jpeg');
  background-size: 100% 100%;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
`

export default Solution;