import Container from 'components/admin/notice/Container';
import ListBox from 'components/admin/notice/ListBox';
import Title from 'components/admin/Title';
import Header from 'components/admin/Header';
import Pagination from 'components/common/Pagination';
import { getPageNotice } from 'controllers/notice';
import Date from 'components/admin/Date';
import PostTitle from 'components/admin/PostTitle';
import Button from 'components/admin/Button';
import Post from 'components/admin/Post';
import { useContext, useEffect, useState } from 'react';
import { modalContext } from 'modules/contexts/modal';
import { authContext } from 'modules/contexts/auth';
import { pushModal } from 'modules/actions/modal';
import Login from './Login';
import { getPhoto } from 'controllers/gallery';
import PostGallery from './PostGallery';
import { useHistory } from 'react-router-dom';

const Gallery = () => {

  const [result, setResult] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const { dispatch: modalDispatch } = useContext(modalContext);
  const context = useContext(authContext);
  const history = useHistory();

  const addGalleryModal = () => modalDispatch(pushModal('postGallery',PostGallery));

  useEffect(() => {
    getPhoto(page)
    .then( res => {
      setResult(res);
      setIsLoading(false);
    })
    
    history.push({
      search: `&page=${page}`
    })
  }, []);


  if(isLoading) return <></>

  return (
    <>
    {
      context.state.user_id ? (
        <>
        <Header />
     <Container>
      <Title>갤 러 리</Title>
      <Button type="button" onClick={() => addGalleryModal()}>글 쓰 기</Button>
      <ListBox>
        {
          result && result.map((data:any, idx:number) => (
            <Post to={`/admin/gallery/${data._id}`} key={idx}>
              <Date>
                {
                  data.date.slice(0,10)
                }
              </Date>
              <PostTitle>
                {
                  data.title
                }
              </PostTitle>
            </Post>
          ))
        }
      </ListBox>
      <Pagination page={page} setPage={setPage}/>
    </Container>
        </>
      ) : <Login />
    }
    </>
    
  )
}

export default Gallery;