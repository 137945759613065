import styled from 'styled-components';
import Title from 'components/home/center/Title';
import SubTitle from 'components/home/center/SubTitle';
import Card from 'components/home/center/Card';

const Center = () => {
  return (
    <Container>
      <Title>센터입소안내</Title>
      <SubTitle>장기요양등급 결정을 받은 후 전화/방문 상담을 통하여 입소 대기 접수가 가능합니다.</SubTitle>
      <Card />
    </Container>

  )
}

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 400px;
`;

export default Center;