import ProgramSide from "components/content/aside/ProgramSide"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import Section from "components/content/body/common/Section"
import SubHeader from "components/content/body/common/SubHeader"
import Pay from "components/content/body/daycare/Pay"
import PayTable from "components/content/body/daycare/PayTable"
import { Helmet } from "react-helmet"

const Daycare = () => {

  return (
    <>
      <Helmet>
        <title>성모사랑 데이케어 - 데이케어란?</title>
      </Helmet>
      <ProgramSide cur="데이케어란" />
      <Container>
        <Header>
          데이케어란?
          <span>홈 &gt; 성모사랑 프로그램안내 &gt; 데이케어란?</span>
        </Header>
        <Section>
          <SubHeader>•데이케어란?</SubHeader>
          건강보험공단 노인장기요양보험 기준에 의거하여 뇌졸증 파킨슨 및 치매와 노인성 만성질환으로 장기요양1~5등급을 받으신 어르신을 대상으로 주,야간보호서비스를 제공함으로서 정서적 지지와 신체적 건강증진을 제공합니다.
          <br/><br/>
          어르신 데이케어는 부양에 정신적,육체적 그리고 경제활동 등으로 지친 가족의 부담을 경감시켜 드리고 남은 잔존능력을 최대한 극대화 시켜 안정적인 노후생활을 보장하며 편안하고 행복한 쉼터를 제공하는데 그 목적이 있습니다.
        </Section>
        <Section>
          <SubHeader>•2020년 주야간보호센터 이용시간별 장기요양 수가</SubHeader>
          건강보험공단(노인장기요양보험수가표)
          <Pay />
        </Section>
        <Section>
          <SubHeader>•장기요양적용 유형별 수가표</SubHeader>
          <PayTable />
        </Section>
      </Container>
    </>
  )
}

export default Daycare;