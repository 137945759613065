import styled from 'styled-components';

const Pagination = ({page, setPage}:IProp) => {

  return (
    <Wrapper>
      <Button src="/images/left.png" alt="왼쪽" />
      <Button src="/images/right.png" alt="오른쪽" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  & img:first-child {
    margin-right: 20px;
  }
`;

const Button = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

interface IProp {
  page: number;
  setPage : (num:number) => void;
}

export default Pagination;