import { ReactNode } from "react";

export interface IProp {
  children : ReactNode;
}

export interface IUserState {
  user_id : string;
}

const userState:IUserState = {
  user_id : '',
}

export default userState;