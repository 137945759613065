import palette from 'lib/styles/palette';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const List = styled(Link)`
  width : 100%;
  border-bottom: 1px solid ${palette.blue4};
  display: flex;
  flex-wrap: wrap;
  color: black;
  margin-bottom: 10px;
  &>div {
    padding: 3px !important;
  }
`;

export default List;