import Container from 'containers/home';
import { Helmet } from 'react-helmet';

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>성모사랑 데이케어센터 - 주야간보호시설</title>
      </Helmet>
      <Container />
    </>
  )
}

export default Homepage;