import CenterSide from "components/content/aside/CenterSide"
import Container from "components/content/body/common/Container"
import Header from "components/content/body/common/Header"
import Photozone from "components/content/body/facility/Photozone"
import { Helmet } from "react-helmet"

const Facility = () => {

  return (
    <>
    <Helmet>
      <title>성모사랑 데이케어 - 시설현황</title>
    </Helmet>
    <CenterSide cur="시설현황" />
    <Container>
      <Header>
        시설현황<span>홈 &gt; 센터소개 &gt; 시설현황</span>
      </Header>
      <Photozone>
        
      </Photozone>
    </Container>
    </>
  )
}

export default Facility;