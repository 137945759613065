import styled from 'styled-components';

const Title = styled.div`
  max-width: 1024px;
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto;
  padding-bottom: 10px;
`;

export default Title;