import Container from 'components/admin/modal/Container';
import Title from 'components/admin/modal/Title';
import InputTitle from 'components/admin/modal/InputTitle';
import TextArea from 'components/admin/modal/TextArea';
import FileBtn from 'components/admin/modal/FileBtn';
import SubmitBtn from 'components/admin/modal/SubmitBtn';
import { useState } from 'react';
import { postPhoto } from 'controllers/gallery';

const PostGallery = ({ PreventModalOff, ModalOff, args }:any) => {

  const [img, setImg] = useState<File[]>([]);
  const [content, setContent] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const onSubmit = () => {
    const formdata = new FormData();
    formdata.append('title', title);
    formdata.append('content',content);
    for(let i=0; i<img.length; i++) {
      formdata.append('files',img[i]);
    }
    postPhoto(formdata);
    ModalOff();
    window.location.reload();
  }

  return (
    <Container onMouseDown={PreventModalOff}>
      <Title>제 목</Title>
      <InputTitle placeholder="제목을 입력해주세요." type="text" onChange={(e:any) => setTitle(e.target.value)}/>
      <Title>내 용</Title>
      <TextArea onChange={(e:any) => setContent(e.target.value)} />
      <SubmitBtn onClick={() => onSubmit()}>작 성</SubmitBtn>
      <FileBtn type="file" accept="image/png, image/jpeg, image/jpg" 
              multiple 
              onChange={(e:any) => setImg(e.target.files)}
      />
    </Container>
  )
}

export default PostGallery;