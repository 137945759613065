import palette from 'lib/styles/palette';
import styled from 'styled-components';

const SubmitBtn = styled.button`
  width: 180px;
  height: fit-content;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  float: right;
  cursor: pointer;
  &:hover{
    border: 1px solid ${palette.black};
  }
`;

export default SubmitBtn;