import palette from 'lib/styles/palette';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  height: 600px;
  border-radius: 8px;
  background-color: ${palette.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-sizing: border-box;
  padding: 8px;
`;

export default Container;