import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  max-width: 864px;
  width: 100%;
  min-height: 600px;
  height: 100%;
  padding: 30px 0 100px 0;
`;

export default Container;