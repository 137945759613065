import palette from 'lib/styles/palette';
import styled from 'styled-components';

import Title from 'components/home/map/Title';
import Box from 'components/home/map/Box';
import MiniMap from './MiniMap';
import Address from './Address';

const Map = () => {
  return (
    <Container>
      <Title>찾아오시는 길</Title>
      <Box>
        <MiniMap />
        <Address />
      </Box>
    </Container>

  )
}

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 600px;
  color: white;
  background-color: ${palette.gray2};
`;

export default Map;