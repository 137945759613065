import InsuranceSide from "components/content/aside/InsuranceSide"
import { Helmet } from "react-helmet"
import Container from "components/content/body/common/Container";
import Section from "components/content/body/common/Section";
import SubHeader from "components/content/body/common/SubHeader";
import Header from "components/content/body/common/Header";
import Img from 'components/content/body/insurance/Img';

const AboutInsurance = () => {

  return (
    <>
    <Helmet>
      <title>성모사랑 데이케어 - 노인장기요양보험이란?</title>
    </Helmet>
    <InsuranceSide cur="설명" />
    <Container>
      <Header>노인장기요양보험이란<span>홈 &gt; 노인장기요양보험 &gt; 노인장기요양보험이란</span></Header>
      <Section>
        <SubHeader>•노인장기 요양 보험이란?</SubHeader>
        '65세 이상의 노인' 또는 "치매, 뇌혈관질환 등 노인성만성질환이 있는 65세 미만의 자"가 6개월 이상 동안 혼자서 일상생활을 수행하기 어려워 공단으로부터 입소 수금자로 판정받은 경우 장기요양기관(시설/재가급여)으로 부터 신체활동 또는 가사활동, 인지활동 지원 등의 "장기요양급여"를 받을 수 있는 제도입니다.
      </Section>
      <Section>
        <SubHeader>•장기요양 등급 구분</SubHeader>
        장기요양등급판정위원회에서 6개월 이상 동안 혼자서 일상생활을 수행하기 어렵다고 인정하는 경우 심신상태 및 장기요양이 필요한 등급 판정기준에 따라 다음과 같이 수급자로 판정합니다.
      </Section>
      <Img />
    </Container>
    </>
  )
}

export default AboutInsurance;