import palette from 'lib/styles/palette';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Post = styled(Link)`
  display: block;
  margin-bottom: 20px;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  color: ${palette.black};
  padding-bottom: 10px;
  border-bottom: 2px solid ${palette.blue4};
`;

export default Post;