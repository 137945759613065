import styled from 'styled-components';

const Delete = styled.button`
  width: 70px;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

export default Delete;