import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 4px;
`

export default Input;