import {userInfo} from 'controllers/auth';
/*
	Thunks
*/
export const getUser = async(dispatch: any) => {

	return userInfo()
					.then(async(data) => {
						dispatch(updateUser(data));
					})
					.catch(err => {
						return Promise.reject(err);
					});
}

export const outUser = async(dispatch: any) => {
	return await dispatch(deleteUser());
}

/*
	Actions Types
*/
export const UPDATE_USER= 'auth/UPDATE_USER' as const;
export const DELETE_USER = 'auth/DELETE_USER' as const;


/*
	Action Payload Types
*/


/*
	Actions Constructors
*/
export const updateUser = (data:any) => ({type: UPDATE_USER, payload : data});
export const deleteUser = () => ({type: DELETE_USER});

export type ActionType = 
	| ReturnType<typeof updateUser>
	| ReturnType<typeof deleteUser>